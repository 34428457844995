import React from "react";
import "./Footer.css";
import { data } from "../../constants";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { motion } from "framer-motion";
import { gtagSendEvent } from "../../utils/gtaghelper";

const Footer = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <footer>
        <div className="row align-items-center">
          <div className="col-md-2 col-12">
            <h1
              style={{
                fontSize: "4x0px",
                textDecorationColor: "#b9ff66",
                textDecorationLine: "underline",
              }}
            >
              Quantech
            </h1>
          </div>
          <div className="col-md-8 col-12 ">
            <ul className="navbar-footer">
              {data.Menu.slice(0, -1).map((item, index) => (
                <li key={item.text}>
                  {" "}
                  <a href={item.link}>{item.text}</a>
                </li>
              ))}
              <li key={"terms-and-conditions"}><a href="/terms-and-conditions">Terms & Condition</a></li>
            </ul>
          </div>
          <div className="col-md-2 col-12 text-center text-md-end fs-4">
            <a
              style={{ color: "#b9ff66" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/quantech.pro/"
            >
              <BsInstagram />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "#b9ff66" }}
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
            >
              <BsWhatsapp />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Vision:</span>
              </li>
              <li>
                Crafting wonders with React & Next.js ✨, where every pixel
                tells your unique story.
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Contact us:</span>
              </li>
              <li>
                Email:{" "}
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="mailto:Kiran@quantech.pro"
                >
                  Kiran@quantech.pro
                </a>
              </li>
              <li>
                Phone:{" "}
                <a  onClick={()=>gtagSendEvent("conversion_event_contact","tel:+91-6363053425")}
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+91-6363053425"
                >
                  +91-6363053425
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Address:</span>
              </li>
              <li>10 , Konankunte Cross bangalore - 560062</li>
            </ul>
          </div>
          {/*  <div className="col-md-8 col-12">
          <form className="row form-news">
            <div className="col-lg-6 col-12">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <button type="submit" className="btn-positivus w-100">
                Subscribe to news
              </button>
            </div>
          </form>
        </div> */}

          <div className="rights">
            <p>© {new Date().getFullYear()} QuanTech. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Footer;
