import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet";

import {
  Testimonials,
  CaseStudies,
  Contact,
  Footer,
  Hero,
  Process,
  Team,
  Services,
  Slider,
  Slider1,
  CTA,
  Partners,
  DynamicPage,
  Choose,
  HeroM
} from "./container";
import { Menu } from "./components";
import { gtagSendEvent } from "./utils/gtaghelper";
import TermsAndConditions from "./container/Terms";
import ContactPopup from "./container/Contactpopup/Contactpop";

const App = () => (
  <Router>
    <div className="container">
      <Analytics />
      <SpeedInsights />
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
  path="/website-development" 
  element={
    <>
      <Helmet> 
        <title>Website Development | Quantech</title> 
        <meta name="description" content="We offer professional website development services to build responsive, modern websites that drive business success. Contact us for custom web solutions." />
      </Helmet>
      <Marketing />
    </>
  } 
/>
        <Route path ="terms-and-conditions" element={<TermsAndConditions />}/>
        <Route path="/:slug" element={<DynamicPage />} />
        {/*create a route if no route is matched it shows 404 not found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

const NotFoundPage = () => {
  return <h1>404 Not Found</h1>;
};

const Marketing =()=>{
  return(
  <>
  <HeroM />
  <Slider /> 
  <Partners/>
  <CTA />
  <Testimonials />
  <Slider1 />
  <Contact />
  <ContactPopup />
   <div style={whatsappButtonStyle}>
  <a
   href="https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
   onClick={(event) => {
       gtagSendEvent(
           "conversion_event_contact",
           "https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
       );
   }}
>


        <FaWhatsapp style={iconStyle} />
      </a>
    </div>
  </>
)}

const Home = () => (
  <>
    <Hero />
    <Slider />
    <Services />
    <Team />
    <CTA />
    <Partners />
    <CaseStudies />
    <Process />
    <Choose />
    {/*   <VideoReview /> */}
    <Testimonials />
    <Slider1 />
    <Contact />
    <div style={whatsappButtonStyle}>
  <a
   href="https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
   onClick={(event) => {
       gtagSendEvent(
           "conversion_event_contact",
           "https://api.whatsapp.com/send/?phone=916363053425&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
       );
   }}
>


        <FaWhatsapp style={iconStyle} />
      </a>
    </div>
  </>
);
const whatsappButtonStyle = {
  position: "fixed",
  bottom: "30px",
  right: "30px",
  backgroundColor: "#B9FF66",
  padding: "12px",
  borderRadius: "50%",
  zIndex: 1000,
};

const iconStyle = {
  color: "black",
  fontSize: "45px",
};

export default App;
