import React from "react";
import { Helmet } from "react-helmet";
import "./terms.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms & Conditions | QuanTech</title>
        <meta name="description" content="Read the terms and conditions of QuanTech's digital marketing services." />
        <meta property="og:title" content="Terms & Conditions | QuanTech" />
        <meta property="og:description" content="Understand the terms and conditions of QuanTech's digital marketing services." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.quantech.pro/terms-and-conditions" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Terms & Conditions | QuanTech" />
        <meta name="twitter:description" content="Understand the terms and conditions of QuanTech's digital marketing services." />
      </Helmet>
      <center>
        <h1 style={{ textDecoration: "underline", textDecorationColor: "#b9ff66" }}>
          Terms & Conditions
        </h1>
      </center>
      <br /><br />
      <p>
        Welcome to QuanTech. By engaging our digital marketing services, you agree to the
        following terms and conditions:
      </p>
      <h2>Payment Terms</h2>
      <ul className="terms-list">
        <li>50% of the total project cost must be paid before the commencement of work.</li>
        <li>
          Failure to clear the remaining balance within 60 days will result in service discontinuation, and all your data will be removed without prior notice.
        </li>
        <li>
          To reinstate the website, a penalty of ₹5,000 must be paid for the setup of the server and other dependencies.
        </li>
        <li>
          Any overdue invoice will incur a 2% monthly interest charge until fully paid.
        </li>
      </ul>
      <h2>Service Agreement</h2>
      <p>
        QuanTech reserves the right to modify or discontinue any service at its discretion. All
        deliverables will be based on the agreed-upon scope of work. Any additional requests may
        incur extra charges.
      </p>
      <h2>Liability & Data Handling</h2>
      <p>
        QuanTech is not liable for any loss of data due to non-payment, server downtime, or any
        third-party service failure. Clients are responsible for maintaining backups of their
        data.
      </p>
      <h2>Confidentiality</h2>
     <p>
        Both parties agree to keep confidential any proprietary or sensitive information shared
        during the engagement. QuanTech will not disclose client data to third parties without
        consent. <strong>We do not store any client credentials to ensure privacy and security.</strong>
      </p>
      <h2>Intellectual Property</h2>
      <p>
        <strong>All website source code, unless explicitly stated otherwise in a prior agreement, remains the property of QuanTech.</strong> QuanTech reserves the right to reuse non-client-specific components, libraries, or methodologies used in the project.  
      </p>
      <p>
        Additionally, <strong>QuanTech reserves the right to use a similar design for other clients upon request, unless a unique design exclusivity agreement is made.</strong>
      </p>

      <h2>Cancellation & Refund Policy</h2>
      <p>
        Clients may cancel their services at any time by providing written notice. However,
        payments made for the service upon starting are non-refundable. Any work completed up to the cancellation date will
        be billed accordingly.
      </p>
      <h2>Governing Law</h2>
      <p>
        These terms and conditions are governed by the laws of India. Any disputes shall be
        resolved under the jurisdiction of Indian courts.
      </p>
      <p>
        For any questions regarding these terms, please contact us at&nbsp;
        <a style={{ textDecorationColor: "#b9ff66", color: "black" }} href="mailto:Kiran@quantech.pro">
          Kiran@quantech.pro
        </a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
