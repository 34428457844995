import React, { useState, useEffect } from "react";
import "./contactpop.css";
import { motion } from "framer-motion";

const ContactPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Check if the screen width is 768px or less (mobile)
    if (isMobile) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 7777);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          className="popup-overlay"
        >
          <div className="popup-content">
            <h3>🎉 Limited Time Offer! 🎉</h3>
            <p>Get an <b>exclusive 10% discount</b> on our services. Hurry, offer ends soon!</p>
            <div className="popup-buttons">
              <a href="tel:+91-6363053425">
                <button className="btn-positivus" style={{ backgroundColor: "#b9ff66", color: "black", fontWeight: "bold" }}>
                  Claim Offer
                </button>
              </a>
              <button className="btn-positivus" onClick={() => setIsOpen(false)}>Close</button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ContactPopup;
